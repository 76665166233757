import { errorStatusMessage } from './errorStatusMessage';

const lowerRole = {
  1: 'User',
  2: 'Shop',
  3: 'Admin',
  7: 'Superadmin',
};

export const locale = {
  ...errorStatusMessage,
  changeLang: 'Change language',
  changePas: 'Change password',
  logout: 'Logout',
  other: 'Other',
  successAuth: 'Success authorization',
  notRules: 'Insufficient login privileges',

  newPlayer: 'New player',
  userName: 'Username',
  password: 'Password',
  createAccount: 'Create account',
  '/user-create': (role = 1) => `Cash register - new ${lowerRole[role]}`,
  '/users-list': 'Cash register - clients balances',
  '/user-edit': 'Cash register - client edit',
  '/total': 'Cash register - accounting',
  '/': 'Menu',
  '/locale': 'Languages',
  '/change-pas': 'Cash register - Change password',
  '/transaction-history': 'clients - Transaction history',
  '/cashier-jackpots': 'Cashier jackpots',

  '/tree': 'Tree',
  userCardBalances: 'Users cards balances',
  userCard: 'User card',
  accountBalance: 'Account balance',
  nickname: 'Nickname',
  id: 'Id',
  balance: 'Balance',
  ban: 'Ban',
  banSuccess: 'Ban success',
  unbanSuccess: 'Unban success',
  unban: 'Unban',
  resetPassword: 'Reset password',
  resetSuccess: 'Reset password success',
  networkError: 'Network error',
  createSuccess: 'Create success',
  topUp: 'TopUp',
  withdrawal: 'Withdrawal',
  topUpSuccess: 'TopUp success',
  withdrawalSuccess: 'Withdrawal success',
  topUpWithdrawal: 'TopUp/Withdrawal',
  ok: 'Ok',
  theNewPassword: 'The new password',
  for: 'for',
  startDate: 'Start date',
  endingDate: 'Ending date',
  display: 'Display',
  email: 'E-mail',
  date: 'Date',
  amount: 'Amount',

  languageSelection: 'Language selection',
  login: 'Login',

  cashRegister: 'Cash register',
  createUser: {
    1: 'New user',
    2: 'New shop',
    3: 'New admin',
    7: 'New superadmin',
  },
  balances: {
    1: 'User cards balances',
    2: 'Shop cards balances',
    3: 'Admins cards balances',
    7: 'Superadmins cards balances',
  },
  cashiersTransactions: {
    1: 'Cashier transactions',
    2: 'Shop transactions',
    3: 'Admins transactions',
    7: 'Superadmins transactions',
  },
  partners: 'Partners',
  revenuBalances: 'Revenu balances',
  transactionHistory: {
    1: 'Transaction history',
    2: 'Transaction history',
    3: 'Transaction history',
    7: 'Transaction history',
  },
  cashierJackpots: {
    1: 'Cashier jackpots',
    2: 'Cashier jackpots',
    3: 'Cashier jackpots',
    7: 'Cashier jackpots',
  },

  total: 'Total',
  term: 'Term',
  deposit: 'Deposit',
  cashPayment: 'Cash payment',
  cashPayout: 'Cash payout',
  withdraw: 'Withdraw',
  search: 'Search',
  errorPassword: 'The password must contain at least 2 letters and 6 numbers',
  noPass: 'Password must not be empty!',
  dataUpdate:
    'Data is updated once every 12 hours.Therefore, the data for the current day may be incorrect.',
  tree: {
    1: 'Tree',
    2: 'Tree',
    3: 'Tree',
    7: 'Tree',
  },
  players: 'Players',
  subusers: 'Sub-users',

  yes: 'Yes',
  no: 'No',
  playerCreated: 'The player was created successfully!',
  wantToPrint: 'Do you want to print it?',
  credentials: 'Credentials',
  print: 'Print',

  jackpotWinnersList: 'Jackpot winners list',
  startAmount: 'Start amount',

  winnerID: 'Winner ID',
  winDate: 'Win date',
  amountOfTheWinningJackpot: 'Amount of the winning jackpot',
};
