import React from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import SVGInline from 'react-svg-inline';

import crossSVG from './img/cross.svg';

import './Modal.scss';

const Modal = ({ closeFunction, children, widthContent, withCross = false }) => {
  const b = block('modal-mobile');

  const stop = e => e.stopPropagation();

  const content = (
    <div className={b()} onMouseDown={withCross ? null : () => closeFunction()}>
      <div className={b('content-container')} onMouseDown={stop} onMouseUp={stop} onClick={stop} style={{ width: widthContent }}>
        {withCross && <SVGInline className={b('cross').toString()} svg={crossSVG} onMouseDown={() => closeFunction()}/>}
        {children}
      </div>
    </div>
  );

  return ReactDOM.createPortal(
    content,
    document.getElementById('modal-root'),
  );
};

Modal.propTypes = {
  closeFunction: PropTypes.func,
  children: PropTypes.element.isRequired,
  widthContent: PropTypes.string,
};

export default Modal;
