import React, { useMemo, useCallback } from "react";
import block from "bem-cn";
import SVGInline from "react-svg-inline";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import arrowSvg from "./img/arrow.svg";
import homeSvg from "./img/home.svg";
import loginSvg from "./img/login.svg";

import "./Header.scss";

const needRoleRoutes = ["/user-create"];

const Header = ({ location, history }) => {
  const b = block("header");
  const locale = useSelector((state) => state.locale.locale);
  const { isAuth, role, balance, currency, id, name } = useSelector(
    (state) => state.auth
  );

  const isLocale = location.pathname.indexOf("/locale") !== -1;
  const isNeedBackButton = useMemo(
    () => location.pathname !== "/" && (isAuth || isLocale),
    [location.pathname, isAuth, isLocale]
  );

  const onClick = useCallback(() => {
    if (isNeedBackButton) history.goBack();
  }, [history, isNeedBackButton]);
  const route = location.pathname.substring(
    0,
    location.pathname.lastIndexOf("/") === 0
      ? location.pathname.length
      : location.pathname.lastIndexOf("/")
  );
  let text = needRoleRoutes.includes(route)
    ? locale[route](role)
    : locale[route];
  if (!isAuth && !isLocale) text = locale.login;

  return (
    <div className={b()}>
      <div className={b("left")} onClick={onClick}>
        {isNeedBackButton ? (
          <SVGInline className={b("arrow").toString()} svg={arrowSvg} />
        ) : (
          <SVGInline
            className={b("home").toString()}
            svg={isAuth ? homeSvg : loginSvg}
          />
        )}
      </div>
      <div className={b("center")}>
        <div className={b("text")}>{text}</div>
      </div>
      <div className={b("right")}>
        {isAuth ? (<>
          <div className={b('user-info')}>
            <span className={b('user-value')}>{name}</span>
            <span className={b('user-value')}>{`ID: ${id}`}</span>
          </div>
          <div className={b("balance")}>{`${balance} ${currency}`}</div>
        </>) : (
          ""
        )}
      </div>
    </div>
  );
};

export default withRouter(Header);
