import React, { useMemo, useState } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { Link } from 'react-router-dom';

import awaySvg from './img/away.svg';

import './MenuElement.scss';

const MenuElement = ({ locale, item, role = 1 }) => {
  const b = block('menu-element');
  const elem = useMemo(
    () => (
      <Link className={b('element')} key={item.textId} to={item.route}>
        {locale[item.textId]?.[role]}
        <SVGInline svg={awaySvg} className={b('image').toString()} />
      </Link>
    ),
    [b, item, locale, role]
  );

  if (item.role && role !== item.role) {
    return null;
  }

  return (
    <React.Fragment>
      <div className={b('element-list')}>{elem}</div>
    </React.Fragment>
  );
};

export default MenuElement;
