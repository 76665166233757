import React, { useState, useRef } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import PT from 'prop-types';
import { useReactToPrint } from 'react-to-print';

import Button from 'components/Button';
import logoIMG from 'shared/img/logo.png';

import './UserPrint.scss';

const b = block('user-print');

const UserPrint = ({ onClose, userName, password }) => {
  const locale = useSelector(state => state.locale.locale);
  const printRef = useRef(null);

  const [isQuestion, setIsQuestion] = useState(true);

  const handleYesClick = () => setIsQuestion(false);

  const date = new Date().toLocaleString();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'for-print',
  });

  return (
    <article className={b()}>
      {isQuestion ?
        <>
          <h3 className={b('title')}>{locale.playerCreated}</h3>
          <h5 className={b('subtitle')}>{locale.wantToPrint}</h5>
          <div className={b('buttons')}>
            <Button text={locale.yes} callBack={handleYesClick} color="green" />
            <Button text={locale.no} callBack={onClose} color="blue" />
          </div>
        </>
      : <section className={b('receipt')}>
          <div className={b('info')} ref={printRef}>
            <h3 className={b('title', { type: 'receipt' })}>{locale.credentials}</h3>
            <div className={b('name')}>{`${locale.date}:`}</div>
            <div className={b('value')}>{date}</div>
            <div className={b('name')}>{`${locale.userName}:`}</div>
            <div className={b('value')}>{userName}</div>
            <div className={b('name')}>{`${locale.password}:`}</div>
            <div className={b('value')}>{password}</div>
            <img className={b('logo')} src={logoIMG} alt="" />
          </div>
          <Button text={locale.print} callBack={handlePrint} color="green" />
        </section>}
    </article>
  );
};

UserPrint.propTypes = {
  onClose: PT.func.isRequired,
  userName: PT.string,
  password: PT.string,
}

export default UserPrint;
