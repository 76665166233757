// export default [
//   {
//     textId: 'cashRegister',
//     items: [
//       {
//         textId: 'createUser',
//         route: '/user-create',
//       },
//       {
//         textId: 'balances',
//         route: '/users-list',
//       },
//       {
//         textId: 'cashiersTransactions',
//         route: '/total',
//       },
//       {
//         textId: 'transactionHistory',
//         route: '/transaction-history',
//       }
//     ]
//   },
//   {
//     textId: 'partners',
//     items: [
//       {
//         textId: 'transactionHistory',
//         route: '/transaction-history',
//       }
//     ]
//   },
// ]

export default [
  {
    textId: 'createUser',
    route: '/user-create',
  },
  {
    textId: 'balances',
    route: '/users-list',
  },
  {
    textId: 'cashiersTransactions',
    route: '/total',
  },
  {
    textId: 'cashierJackpots',
    route: '/cashier-jackpots',
    role: 1,
  },
  {
    textId: 'transactionHistory',
    route: '/transaction-history',
  },
  {
    textId: 'tree',
    route: '/tree',
  },
];
