import React from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './Button.scss';

const Button = ({ text, callBack, disabled = false, color = '', type = 'button', link }) => {
  const b = block('button');
  return link ? (
    <Link
      className={b({ disabled, color })}
      to={link}
    >
      {text}
    </Link>) : (
      <button
        type={type}
        className={b({ disabled, color })}
        onClick={disabled ? f => f : callBack}
        disabled={disabled}
      >
        {text}
      </button>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  link: PropTypes.string,

  callBack: PropTypes.func,
};

export default Button;
